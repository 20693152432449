import mixpanelModule from 'mixpanel-browser';

const base = process.env.REACT_APP_MIXPANEL_TOKEN
  ? mixpanelModule
  : {
      identify: () => {},
      track: () => {},
      reset: () => {},
    };

export default base;

import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1.5);
    opacity: 1;
  }

  75%,
  100% {
    transform: scale(1);
    opacity: 0.15;
  }
`;

interface LoadingDotProps {
  delay: number;
}

const LoadingDot = styled.div<LoadingDotProps>`
  width: 10px;
  height: 10px;
  background: currentColor;
  border-radius: 100%;
  margin: 0 5px;
  animation: ${pulse} 1s ease-out infinite;
  animation-delay: ${(props) => props.delay}ms;
  opacity: 0.15;
`;

interface LoadingDotsWrapperProps {
  inset?: boolean;
  inButton?: boolean;
  horizontalAlign?: 'left' | 'center';
}

const LoadingDots = ({
  horizontalAlign,
  inset,
  inButton,
}: LoadingDotsWrapperProps): ReactElement => (
  <LoadingDotsWrapper
    horizontalAlign={horizontalAlign}
    inset={inset}
    inButton={inButton}
  >
    <LoadingDot delay={0} />
    <LoadingDot delay={250} />
    <LoadingDot delay={500} />
  </LoadingDotsWrapper>
);

const LoadingDotsWrapper = styled.div<LoadingDotsWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.horizontalAlign === 'left' ? 'flex-start' : 'center'};
  ${(props) => (props.inset ? `margin: 80px 0;` : '')};

  ${(props) =>
    props.inButton
      ? `
    ${LoadingDot} {
      width: 8px;
      height: 8px;
      margin: 0 3px;
    }
  `
      : ``}
`;

export default LoadingDots;

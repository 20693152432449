import { darken, lighten, readableColor, transparentize } from 'polished';
import { createGlobalStyle, css } from 'styled-components';

type ColorValues = [
  color400: string,
  color500: string,
  color600: string,
  color700: string,
];

type ColorMap = Record<string, ColorValues>;

const colorMap: ColorMap = {
  blue: ['#60a5fa', '#1A6EF4', '#1555e1', '#023bb9'],
  cyan: ['#22d3ee', '#06b6d4', '#0891b2', '#0e7490'],
  red: ['#f87171', '#dc2626', '#b91c1c', '#b91c1c'],
  orange: ['#fb923c', '#f97316', '#ea580c', '#c2410c'],
  amber: ['#fbbf24', '#f59e0b', '#d97706', '#b45309'],
  pink: ['#f472b6', '#ec4899', '#db2777', '#be185d'],
  teal: ['#2dd4bf', '#14b8a6', '#0d9488', '#0f766e'],
  purple: ['#c084fc', '#9F41F6', '#8926e3', '#7e22ce'],
  fuchsia: ['#e879f9', '#d946ef', '#c026d3', '#a21caf'],
  green: ['#4ade80', '#22c55e', '#16a34a', '#15803d'],
  rose: ['#fb7185', '#f43f5e', '#e11d48', '#be123c'],
  indigo: ['#818cf8', '#6366f1', '#4f46e5', '#4338ca'],
  gray: ['#94a3b8', '#64748b', '#475569', '#334155'], // Tailwind Slate
};

const darkTextColors = ['cyan', 'rose', 'teal'];

const mapColor = ([key, val]: [string, ColorValues]) => css`
  --${key}: ${val[1]};
  --text-on-${key}: #fff;
  --${key}-accent: ${val[2]};
  --${key}-progress-marker: ${val[2]};
  --${key}-light: ${val[0]};
  --${key}-dark: ${val[3]};
  --${key}-hover: ${val[3]};
  --${key}-bg: ${transparentize(0.9, val[1])};
  --${key}-status-text: ${val[3]};
  --${key}-status-bg: ${transparentize(0.9, val[1])};
  --${key}-status-bg-hover: ${transparentize(0.8, val[1])};
  --${key}-status-done-text: #fff;
  --${key}-status-done-bg: ${val[2]};
  --${key}-status-done-hover: ${darken(0.05, val[2])};
  --${key}-status-border: ${darken(0.05, val[1])};
  --${key}-status-shadow: ${transparentize(0.5, val[1])};
`;

const mapDarkColor = ([key, val]: [string, ColorValues]) => css`
  /* --${key}-status-bg: ${val[3]}; */
  --${key}-hover: ${val[0]};
  --${key}-status-text: ${val[0]};
  /* --${key}-status-done-bg: ${val[1]}; */
  --${key}-progress-marker: ${val[0]};
`;

export const GlobalStyles = createGlobalStyle`
  :root {
    --gutter-width: 30px;
    --sidebar-width: 240px;

    --bg-color: #f8fafc;
    --modal-bg-color: ${transparentize(0.25, '#f8fafc')};
    --card-bg: #fff;
    --text-color: #465872;
    --placeholder-color: rgba(3, 43, 68, 0.5);
    --text-color-strong: #1e293b;
    --table-alt-row-bg: ${transparentize(0.5, '#f8fafc')};
    --border-color: #e2e8f0;
    --score-bg-color: ${transparentize(0.6, '#e2e8f0')};
    --border-color-dark: ${darken(0.1, '#e2e8f0')};
    --border-color-darker: ${darken(0.2, '#e2e8f0')};
    --box-shadow-color: rgba(156, 163, 175, 0.09);
    --box-shadow-elevated-color: rgba(156, 163, 175, 0.25);
    --timeline-card-border-color: transparent;
    --timeline-card-done-bg: #d3d8e0;
    --timeline-card-done-text: #535765;
    --timeline-card-done-status: rgba(0, 0, 0, 0.25);
    --timeline-card-text-shadow: 0 1px 0 rgba(0,0,0,0.2);
    --timeline-card-done-text-shadow: none;
    --timeline-grid-border-color: rgba(113, 142, 193, 0.1);
    --status-done-text-color: #fff;

    --kanban-col-bg: rgba(156, 163, 175, 0.05);
    --kanban-col-bg-hover: rgba(156, 163, 175, 0.1);

    --board-border: var(--timeline-grid-border-color);
    --board-weekday-bg: var(--card-bg);
    --board-weekend-bg: var(--bg-color);

    --timeline-col-bg: transparent;
    --timeline-col-weekend-bg: rgba(36, 45, 101, 0.05);

    --action-text-color: var(--text-color);
    --action-button-bg-color: rgba(31, 89, 170, 0.04);
    --action-button-border-color: var(--border-color);

    ${Object.entries(colorMap).map(mapColor)}

    ${darkTextColors.map(
      (key) => css`
      --text-on-${key}: #000;
      --${key}-accent: ${colorMap[key][0]};
      --text-shadow-on-${key}: 0 1px 0 rgba(255,255,255,0.2);
    `,
    )}

    /* --blue-dark: #1480d4; */

    --blue-selected-overlay: ${transparentize(0.96, '#1A6EF4')};
    --table-row-bg: #fbfcfd;
  }

  @media screen and (max-width: 1000px) {
    :root {
      --gutter-width: 15px;
    }
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --table-row-bg: #14151c;
      --bg-color: #0f1014;
      --modal-bg-color: ${transparentize(0.25, '#0f1014')};
      --card-bg: #16171d;
      // --text-color: #9aa7bf;
      --text-color: #abbad6;
      --placeholder-color: rgba(171, 186, 214, 0.65);
      --text-color-strong: #fff;
      --table-alt-row-bg: rgba(92, 152, 255, 0.02);
      --border-color: #242935;
      --score-bg-color: ${transparentize(0.6, '#242935')};
      --border-color-dark: ${lighten(0.1, '#242935')};
      --border-color-darker: ${lighten(0.3, '#242935')};
      --box-shadow-color: rgba(0, 0, 0, 0.4);
      --box-shadow-elevated-color: rgba(0, 0, 0, 0.5);
      --timeline-card-border-color: rgba(255, 255, 255, 0.15);
      --timeline-card-done-bg: #2e333c;
      --timeline-card-done-text: #fff;

      --kanban-col-bg: #0c0d10;
    --kanban-col-bg-hover: var(--card-bg);

      --status-done-text-color: var(--bg-color);

      --action-button-bg-color: rgba(148, 191, 255, 0.05);

      // --board-border: #292a30;
      // --board-weekday-bg: #1e2026;
      // --board-weekend-bg: #1B1C22;

      --timeline-col-weekend-bg: rgba(0, 0, 0, 0.25);
      --timeline-card-done-status: rgba(0, 0, 0, 0.4);
      --timeline-card-done-text-shadow: var(--timeline-card-text-shadow);
      // --action-text-color: var(--text-color-strong);

      --action-button-border-color: var(--bg-color);

      ${Object.entries(colorMap).map(mapDarkColor)}
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  :focus-visible {
    outline: 2px solid var(--blue);
  }

  html,
  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--bg-color);
    color: var(--text-color);
    overflow: hidden;
  }

  html,
  body,
  input,
  button {
    font-family: system-ui, 'Helvetica Neue', arial, sans-serif;
    font-weight: 400;
  }

  * {
    box-sizing: border-box;
  }

  #app,
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    // overflow: hidden;
    max-height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2 {
    color: var(--text-color-strong);
    margin-top: 0;
  }

  // Ember port
  .board {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  max-height: 100%;
  overflow: auto;
  background-color: var(--bg-color);
  overscroll-behavior: contain;
  display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.board-days {
  flex-shrink: 0;
  display: flex;
  // overflow: none;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--bg-color);
  height: 60px;
  width: var(--board-width);
  border-bottom: 1px solid var(--timeline-grid-border-color);
  user-select: none;
}

.board-single-day {
  width: var(--day-width);
  flex-shrink: 0;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  text-shadow: 0px 1px 0px var(--card-bg);
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.board-single-day--weekend .board-single-day--num {
  opacity: 0.5;
}

.board-single-day--num {
  transition: opacity 200ms linear;
}

.board-only-week-labels .board-single-day .board-single-day--num {
  opacity: 0;
}

.board-only-week-labels
  .board-single-day.board-single-day--small-label
  .board-single-day--num {
  opacity: 1;
}

.board-only-month-labels .board-single-day .board-single-day--num {
  opacity: 0;
}

.board-single-day--month-short {
  display: none;
}

.board-only-month-labels .board-single-day--month-short {
  display: inline;
}

.board-only-month-labels .board-single-day--month-long {
  display: none;
}

.board-single-day--month {
  height: calc(1em + 5px);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

.board-single-day--show-month {
  color: var(--text-color-strong);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background: var(--border-color);
    top: 100%;
    bottom: 0;
    left: 0;
  }

  .board-single-day--num {
    opacity: 1;
  }
}

.board-single-day--today {
  color: var(--rose);
  opacity: 1;

  .board-single-day--num {
    opacity: 1;
  }
}

.board-inner {
  height: 100%;
  position: relative;
  // overflow-x: hidden;
  // overflow-y: auto;
  min-height: calc(var(--highest-vertical) * 75px + 20px + 55px + 40px);
  min-width: 100%;
  width: var(--board-width);
  background-color: var(--body-bg);
  background-size: var(--day-width) calc(100% - 1px);
  background-image: linear-gradient(to right, var(--timeline-grid-border-color) 1px, var(--bg-color) 1px),
    linear-gradient(to bottom, var(--timeline-grid-border-color) 1px, var(--bg-color) 1px);
}

.board-track {
// width: var(--board-width);
// position: absolute;
// top: 0;
// bottom: 0;
// left: 50%;
// transform: translateX(-50%);
}

.grid {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@supports (background-image: paint(roadmap-grid)) {
  .board-inner {
    background-size: calc(var(--day-width) * 7) 100%;
    background-image: paint(roadmap-grid);
  }
}

.board-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.today-line {
  width: var(--day-width);
  background: rgba(255, 36, 54, 0.1);
  border-left: 1px solid rgba(255, 36, 54, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--today-offset) * var(--day-width));
}

.day {
  width: var(--day-width);
  height: 100%;
  /* background: rgba(32, 33, 40, 0.75); */
  /* border: 1px solid rgba(255, 255, 255, 0.05); */
  border-right-width: 0;
  flex-shrink: 0;
}

.card-hold {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 0 3px;
  // transform: translate(
  //   calc(var(--day-width) * var(--day-offset)),
  //   calc(var(--card-vertical) * 75px + 20px)
  // );
  position: absolute;
  top: calc(var(--card-vertical) * 75px + 20px);
  left: calc(var(--day-width) * var(--day-offset));
  /* overflow: hidden; */
}

.board--reflowing .card-hold,
.board--reflowing .card-hold + .card-details {
  transition: all 200ms ease-out;
}

.card-draft .card-hold {
  opacity: 0.5;
}

.card {
  position: absolute;
  height: 55px;
  background: var(--card-color);
  border: 1px solid var(--timeline-card-border-color);
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px var(--box-shadow-color);
  padding: 0 10px;
  cursor: pointer;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  // overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
  /* opacity: 0.8; */
  // transition: all 200ms ease-out;
  z-index: 20;
  width: calc((var(--day-width) * var(--card-duration)) - 5px);
}

.card-dragging {
  opacity: 1;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.card-container {
  display: contents;
}

.card-container--dragging {
  /* transform: scale(1.05); */
  z-index: 30;
  position: absolute;
}


.card-container--dragging .card-hold,
.card-container--dragging .card-hold + .card-details {
  transition: none;
}

.card.active {
  border: 1px solid #fff;
}

.card::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity ease 200ms;
}

.card:hover::before,
.card-dragging::before,
.card-resizing::before,
.card:hover .card-resize-handle,
.card-dragging .card-resize-handle,
.card-resizing .card-resize-handle {
  opacity: 1;
}

.card-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 8px;
  opacity: 0;
  transition: opacity ease 200ms;
}

.card-resize-bar {
  pointer-events: none;
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 4px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.4);
}

.card-resize-handle:hover {
  cursor: col-resize;
}

body.is-resizing,
body.is-resizing .card {
  cursor: col-resize !important;
}

.card-resize-handle:hover .card-resize-bar {
  background: rgba(0, 0, 0, 0.7);
}

.card-left-handle,
.card-left-handle .card-resize-bar {
  left: 0;
}

.card-resizing-left .card-left-handle .card-resize-bar {
  background: rgba(0, 0, 0, 0.7);
}

.card-right-handle,
.card-right-handle .card-resize-bar {
  right: 0;
}

.card-resizing-right .card-right-handle .card-resize-bar {
  background: rgba(0, 0, 0, 0.7);
}

.card-details-inner {
  pointer-events: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  position: sticky;
  left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .card-details {
//   pointer-events: none;
//   position: absolute;
//   left: 0;
//   height: 55px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   transform: translate(
//     calc(var(--day-width) * var(--day-offset) + 13px + var(--details-stuck-left)),
//     calc(var(--card-vertical) * 75px + 20px)
//   );
//   max-width: calc(var(--day-width) * var(--card-duration) - 26px);
// }

.card-details {
  position: sticky;
  left: 0;
}

.card-details--too-small {
  opacity: 0;
}

.details-fixed {
  position: fixed;
  transform: none;
}

.details-stuck {
  transform: translate(
    calc(
      var(--day-width) * var(--day-offset) + 13px + var(--details-stuck-left)
    ),
    calc(var(--card-vertical) * 75px + 20px)
  );
}

.card-name {
  font-weight: 600;
  // text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  font-size: 13px;
  color: var(--card-text-color);
  text-shadow: var(--card-text-shadow-color, var(--timeline-card-text-shadow));
}

.card-status {
  display: block;
  width: auto;
  max-width: 100%;
  height: 16px;
  line-height: 1;
  text-shadow: var(--card-text-shadow-color, var(--timeline-card-text-shadow));
}

.card-status > span {
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  /* background: rgba(0,0,0,0.15); */
  /* color: #fff; */
  background: var(--card-accent);
  color: var(--card-text-color);
  padding: 2px 5px;
  border-radius: 2px;
  width: auto;
  white-space: nowrap;
  // overflow: hidden;
  max-width: 100%;
  line-height: 12px;
  vertical-align: top;
}

.card-status > svg {
  vertical-align: top;
}

.card-done {
  --card-color: var(--bg-color) !important;
  --timeline-card-border-color: var(--border-color);
}

.card-done .card-name {
  color: var(--timeline-card-done-text);
  text-shadow: var(--timeline-card-done-text-shadow);
}

.card-done .card::before {
  background: rgba(255, 255, 255, 0.02);
}

.card-done .card-status > span {
  background: var(--card-status-color);
  color: var(--card-status-text-color);
  text-shadow: none;
  /* background: #fff; */
  /* color: var(--card-color); */
}

.tether-element {
  z-index: 10000000;
}
`;

import { setAuthToken } from './cache';
import mixpanel from './mixpanel';

export function logout(e?: React.MouseEvent<HTMLAnchorElement>) {
  e?.preventDefault();

  setAuthToken(null);

  mixpanel.reset();

  (window.location as any) = '/';
}

import { makeVar } from '@apollo/client';
import Cookies from 'js-cookie';

const authKey = 'authToken';

const defaultToken = Cookies.get(authKey) || null;

export const authTokenVar = makeVar<string | null>(defaultToken);

export const setAuthToken = (value: string | null): void => {
  if (value) {
    Cookies.set(authKey, value, {
      expires: 30,
      secure: window.location.protocol === 'https:',
    });
  } else {
    Cookies.remove(authKey);
  }

  authTokenVar(value);
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 80px 20px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
  text-wrap: balance;
`;

const iconColors = {
  info: {
    background: 'var(--blue-bg)',
    color: 'var(--blue)',
  },
  danger: {
    background: 'var(--red-bg)',
    color: 'var(--red)',
  },
};

interface IconWrapperProps {
  alert: 'info' | 'danger';
}

export const IconWrapper = styled.div<IconWrapperProps>`
  display: inline-block;
  background: ${(props) => iconColors[props.alert].background};
  color: ${(props) => iconColors[props.alert].color};
  padding: 20px;
  border-radius: 200px;
  margin-bottom: 40px;
  line-height: 0;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const Footer = styled.footer`
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);

  a {
    color: var(--text-color-strong);
    font-weight: 500;
  }
`;

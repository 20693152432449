export class RoadblockError extends Error {
  subtitle?: string;

  constructor(message: string, subtitle?: string) {
    super(message);

    this.name = 'RoadblockError';

    this.subtitle = subtitle;
  }
}

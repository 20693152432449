import React from 'react';
import { Container, Footer, IconWrapper } from './styles';

interface RoadblockProps {
  icon: JSX.Element;
  title: string;
  body: JSX.Element;
  footer?: JSX.Element;
  alert?: 'info' | 'danger';
}

export default function Roadblock({
  icon,
  title,
  body,
  footer,
  alert = 'info',
}: RoadblockProps): JSX.Element {
  return (
    <Container>
      <IconWrapper alert={alert}>{icon}</IconWrapper>
      <h2>{title}</h2>
      {body}
      {footer ? <Footer>{footer}</Footer> : null}
    </Container>
  );
}

import { GetMeQuery, MeDataFragment, useGetMeQuery } from 'api/graphql';
import LoadingDots from 'components/LoadingDots';
import React, { ReactElement, Suspense, useEffect } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import lazy from 'react-lazy-with-preload';
import Roadblock from 'components/Roadblock';
import { X } from 'react-feather';
import { authTokenVar } from 'utils/cache';
import { useReactiveVar } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import {
  IntercomProps,
  IntercomProvider,
  useIntercom,
} from 'react-use-intercom';
import { RoadblockError } from 'utils/errors';
import { logout } from 'utils/auth';

const Login = lazy(() => import('routes/Login'));
const Signup = lazy(() => import('routes/Signup'));
const Workspaces = lazy(() => import('routes/Workspaces'));
const Account = lazy(() => import('routes/Account'));
const VerifyEmail = lazy(() => import('routes/VerifyEmail'));
const PendingVerification = lazy(() => import('routes/PendingVerification'));
const LoggedInShell = lazy(() => import('routes/LoggedInShell'));
const ForgotPassword = lazy(() => import('routes/ForgotPassword'));
const ResetPassword = lazy(() => import('routes/ResetPassword'));

interface AuthRouteProps {
  isLoggedIn: boolean;
  loggedOut?: boolean;
  path?: string;
  children?: React.ReactChild;
}

function AuthRoute({
  isLoggedIn,
  loggedOut = false,
  children,
  ...rest
}: AuthRouteProps) {
  const check = loggedOut ? !isLoggedIn : !!isLoggedIn;

  const pathname = loggedOut ? '/workspaces' : '/login';

  return (
    <Route {...rest}>
      {({ location }) =>
        check ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    </Route>
  );
}

interface LoggedInRoutesProps {
  me: MeDataFragment;
}

function LoggedInRoutes({ me }: LoggedInRoutesProps) {
  return me.isVerified ? (
    <Switch>
      <Route path="/workspaces">
        <Workspaces me={me} />
      </Route>
      <Route path="/account">
        <Account me={me} />
      </Route>
      <Route path="/:workspace">
        <LoggedInShell />
      </Route>
    </Switch>
  ) : (
    <PendingVerification />
  );
}

const getHighestRole = (user: MeDataFragment) => {
  if (
    user.workspaces.find((workspace) => workspace.membership?.role === 'ADMIN')
  ) {
    return 'Admin';
  }

  if (
    user.workspaces.find((workspace) => workspace.membership?.role === 'EDITOR')
  ) {
    return 'Editor';
  }

  if (
    user.workspaces.find((workspace) => workspace.membership?.role === 'VIEWER')
  ) {
    return 'Viewer';
  }
};

const IdentifyUser = ({ data }: { data?: GetMeQuery }) => {
  const { update: updateIntercom, boot: bootIntercom } = useIntercom();

  useEffect(() => {
    const intercomUser = data?.me
      ? ({
          userHash: data.me.intercomIdentity,
          email: data.me.email,
          userId: data.me.analyticsId.toString(),
          name: data.me.name,
          customAttributes: {
            'Highest Role': getHighestRole(data.me),
          },
          ...(data.me.avatarUrl
            ? {
                avatar: {
                  type: 'avatar',
                  imageUrl: `${data.me.avatarUrl}/avatarSquare48px2x`,
                },
              }
            : {}),
        } as IntercomProps)
      : null;

    if (typeof data !== 'undefined') {
      bootIntercom(intercomUser ? intercomUser : undefined);
    } else if (intercomUser) {
      updateIntercom(intercomUser);
    }

    if (data?.me?.id) {
      Sentry.setUser({ id: data.me.id });

      updateIntercom();

      mixpanel.identify(data.me.analyticsId.toString());
    }
  }, [bootIntercom, data, updateIntercom]);

  return null;
};

const MainApp = (): ReactElement => {
  const hasToken = useReactiveVar(authTokenVar);
  const { loading, data } = useGetMeQuery({ skip: !hasToken });
  const loggedIn = !!data?.me;
  const indexRedirect = loggedIn ? '/workspaces' : '/login';

  const fallbackSubtitle =
    'Sorry, an unknown error occurred. Our engineers have been alerted. Please refresh to try again.';

  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ''}>
      <IdentifyUser data={data} />
      <div id="app">
        <Sentry.ErrorBoundary
          onError={(e) => {
            console.error(e);
          }}
          fallback={(e) => {
            return (
              <Roadblock
                alert="danger"
                icon={<X />}
                title={
                  e.error instanceof RoadblockError
                    ? e.error.message
                    : 'An error has occurred'
                }
                body={
                  <p>
                    {e.error instanceof RoadblockError
                      ? e.error.subtitle || fallbackSubtitle
                      : fallbackSubtitle}
                  </p>
                }
                footer={
                  <>
                    <Link to="/" onClick={logout}>
                      Logout
                    </Link>
                  </>
                }
              />
            );
          }}
        >
          <Suspense fallback={<LoadingDots inset />}>
            {loading ? (
              <LoadingDots inset />
            ) : (
              <Switch>
                <Route path="/" exact>
                  <Redirect to={indexRedirect} />
                </Route>
                <Route path="/verify">
                  <VerifyEmail />
                </Route>
                <AuthRoute
                  isLoggedIn={loggedIn}
                  loggedOut
                  path="/forgot-password"
                >
                  <ForgotPassword />
                </AuthRoute>
                <AuthRoute
                  isLoggedIn={loggedIn}
                  loggedOut
                  path="/password-reset"
                >
                  <ResetPassword />
                </AuthRoute>
                <AuthRoute isLoggedIn={loggedIn} loggedOut path="/login">
                  <Login preloadSignup={Signup.preload} />
                </AuthRoute>
                <AuthRoute isLoggedIn={loggedIn} loggedOut path="/signup">
                  <Signup preloadLogin={Login.preload} />
                </AuthRoute>
                <AuthRoute isLoggedIn={loggedIn}>
                  {data?.me ? <LoggedInRoutes me={data.me} /> : undefined}
                </AuthRoute>
                <Route>
                  <span>Not found</span>
                </Route>
              </Switch>
            )}
          </Suspense>
        </Sentry.ErrorBoundary>
        <div id="window-dialog-root" />
      </div>
    </IntercomProvider>
  );
};

export default MainApp;

import React, { ReactElement, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import MainApp from './App';
import { GlobalStyles } from './styles';
import { withScalars } from 'apollo-link-scalars';
import { ApolloLink } from '@apollo/client/core';
import introspectionResult from './graphql.schema.json';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { Expression, Parser as evalParser } from 'expr-eval';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { lightFormat, parseISO } from 'date-fns';
import { authTokenVar } from 'utils/cache';
import { StyleSheetManager } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Pusher from 'pusher-js';

import PusherLinkClass from './utils/pusherNew';
import { relayStylePagination } from '@apollo/client/utilities';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery,
);

const BEARER_TOKEN = authTokenVar();

const evalFormula = new evalParser();
const evalCache: Record<string, Expression> = {};

const getEvalFormulaFunc = (expression: string) => {
  if (expression in evalCache) {
    return evalCache[expression];
  }

  const parseResult = evalFormula.parse(expression);

  evalCache[expression] = parseResult;

  return evalCache[expression];
};

const pusherLink = process.env.REACT_APP_PUSHER_KEY
  ? new PusherLinkClass({
      pusher: new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
        cluster: 'eu',
        authEndpoint: `${process.env.REACT_APP_API_GRAPHQL_CLIENT_ENDPOINT}/subscriptions/auth`,
        auth: {
          headers: {
            authorization: BEARER_TOKEN,
          },
        },
      }),
    })
  : null;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_CLIENT_ENDPOINT,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authLink = setContext((params: any) => {
  // get the authentication token from local storage if it exists
  const token = authTokenVar();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...params.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const typeDefs = gql`
  extend type Query {
    authToken: String
  }

  extend type Activity {
    tempId: String @client
  }

  extend type Task {
    score: Int @client
    dragStart: Date @client
    dragEnd: Date @client
    dragVertical: Int @client
  }

  extend type Idea {
    score: Int @client
  }
`;

const typesMap = {
  Date: {
    serialize: (parsed: Date) => {
      const parsedDate = parsed instanceof Date ? parsed : new Date(parsed);

      return lightFormat(parsedDate, 'yyyy-MM-dd');
    },
    parseValue: (raw: string | null): Date | null => {
      return raw ? parseISO(raw) : null;
    },
  },
};

const link = ApolloLink.from([
  withScalars({ schema, typesMap } as any),
  ...(pusherLink ? [pusherLink] : []),
  authLink,
  httpLink,
]);

const client = new ApolloClient({
  link,
  typeDefs,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          authToken: {
            read() {
              return authTokenVar();
            },
          },
        },
      },
      Workspace: {
        keyFields: ['slug'],
        fields: {
          task(_, { args, toReference }) {
            return toReference({
              __typename: 'Task',
              id: args?.id,
            });
          },
        },
      },
      Comment: {
        fields: {
          body: {
            read(body) {
              if (body && typeof body === 'string') {
                return JSON.parse(body);
              }

              return body;
            },
          },
        },
      },
      Update: {
        fields: {
          body: {
            read(body) {
              if (body && typeof body === 'string') {
                return JSON.parse(body);
              }

              return body;
            },
          },
        },
      },
      Activity: {
        fields: {
          tempId: {
            read(existing) {
              return existing || null;
            },
          },
        },
      },
      Milestone: {
        fields: {
          description: {
            read(description) {
              if (description) {
                if (typeof description === 'string') {
                  return JSON.parse(description);
                }
              }

              return description;
            },
          },
        },
      },
      Product: {
        fields: {
          description: {
            read(description) {
              if (description) {
                if (typeof description === 'string') {
                  return JSON.parse(description);
                }
              }

              return description;
            },
          },
        },
      },
      Objective: {
        fields: {
          updates: relayStylePagination(),
        },
      },
      Task: {
        fields: {
          updates: relayStylePagination(),
          score: {
            read(existing, args) {
              try {
                const formulaValues = args.readField('formulaValues');

                if (!formulaValues) {
                  return 0;
                }

                const formulaKey = args.readField(
                  'key',
                  args.readField('activeFormula', args.readField('workspace')),
                );

                const expression = args.readField(
                  'expression',
                  args.readField('activeFormula', args.readField('workspace')),
                );

                if (expression && typeof expression === 'string') {
                  if (typeof formulaKey === 'string') {
                    const finalNum = Math.round(
                      getEvalFormulaFunc(expression).evaluate(
                        ((formulaValues as any)?.[formulaKey] || {}) as Record<
                          string,
                          number
                        >,
                      ),
                    );

                    if (Number.isFinite(finalNum)) {
                      return finalNum;
                    }
                  }
                }
              } catch (e) {
                //
              }

              return 0;
            },
          },
          dragStart: {
            read(existing) {
              return existing || null;
            },
          },
          dragEnd: {
            read(existing) {
              return existing || null;
            },
          },
          dragVertical: {
            read(existing) {
              return typeof existing === 'number' ? existing : null;
            },
          },
          description: {
            read(description) {
              if (description) {
                if (typeof description === 'string') {
                  return JSON.parse(description);
                }
              }

              return description;
            },
          },
        },
      },
      Idea: {
        fields: {
          updates: relayStylePagination(),
          insights: relayStylePagination(),
          score: {
            read(existing, args) {
              try {
                const formulaValues = args.readField('formulaValues');

                if (!formulaValues) {
                  return 0;
                }

                const formulaKey = args.readField(
                  'key',
                  args.readField('activeFormula', args.readField('workspace')),
                );

                const expression = args.readField(
                  'expression',
                  args.readField('activeFormula', args.readField('workspace')),
                );

                if (expression && typeof expression === 'string') {
                  if (typeof formulaKey === 'string') {
                    const finalNum = Math.round(
                      getEvalFormulaFunc(expression).evaluate(
                        ((formulaValues as any)?.[formulaKey] || {}) as Record<
                          string,
                          number
                        >,
                      ),
                    );

                    if (Number.isFinite(finalNum)) {
                      return finalNum;
                    }
                  }
                }
              } catch (e) {
                //
              }

              return 0;
            },
          },
          description: {
            read(description) {
              if (description) {
                if (typeof description === 'string') {
                  return JSON.parse(description);
                }
              }

              return description;
            },
          },
        },
      },
      FeedbackNote: {
        fields: {
          description: {
            read(description) {
              if (description) {
                if (typeof description === 'string') {
                  return JSON.parse(description);
                }
              }

              return description;
            },
          },
        },
      },
    },
  }),
});

const App = (): ReactElement => {
  return (
    <StyleSheetManager disableVendorPrefixes>
      <ApolloProvider client={client}>
        <Router>
          <MainApp />
          <GlobalStyles />
        </Router>
      </ApolloProvider>
    </StyleSheetManager>
  );
};

Sentry.init({
  dsn: 'https://df691d2085144c8d8c84b0e9aa60c1af@o550301.ingest.sentry.io/5673781',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'local',
  enabled: process.env.NODE_ENV === 'production',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: 'https://api-eu.mixpanel.com',
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
